import React from 'react';
import classNames from 'classnames';
import { StaticImage } from 'gatsby-plugin-image';
import { SEO } from '../../components/SEO';
import { Header } from '../../components/Header';
import { Layout } from '../../layouts/Layout';
import { Footer } from '../../components/Footer';
import { Link } from 'gatsby';

const Section = ({ className = '', children }) => {
  return (
    <div
      className={classNames(
        'relative flex flex-col overflow-x-hidden items-center justify-center w-full m-auto text-white max-w-screen-sm mx-auto',
        className
      )}
    >
      {children}
    </div>
  );
};

export default function RehabIndexPage({ title = '재활 치료', ...props }) {
  return (
    <Layout className="relative bg-slate">
      <SEO title={title} titleTemplate="" />
      <Header showBreadcrumb noHeight backHref="/" className="sticky top-0 left-0 right-0 z-10" />
      <div className="flex w-full h-[60px] items-center justify-center font-bold text-xl bg-slate text-white">
        {title}
      </div>

      <Section>
        <StaticImage
          src="../../resources/rehab01.png"
          className="w-full h-auto min-h-[300px]"
          objectFit="cover"
          objectPosition="center"
          placeholder="none"
          alt="hero"
          backgroundColor="transparent"
        />
        <div
          className="absolute inset-0 px-4 py-5"
          style={{
            backgroundImage: `linear-gradient(88deg, rgba(0,0,0,0.55) 34%, rgba(0,0,0,0.22) 56%)`,
          }}
        >
          <h1 className="my-2 text-3xl font-extrabold">물리치료</h1>
          <div className="text-sm leading-6">
            <p>운동치료</p>
            <p>통증치료</p>
            <p>기능적 전기자극치료(FES)</p>
            <p>도수치료</p>
          </div>
          <Link to="/rehab/physical">
            <button className="absolute flex items-center justify-start px-5 py-2.5 text-white transition-all bg-white bg-opacity-0 border border-white bottom-4 left-4 right-4 hover:bg-opacity-20">
              더 보기
            </button>
          </Link>
        </div>
      </Section>
      <Section>
        <StaticImage
          src="../../resources/rehab02.png"
          className="w-full h-auto min-h-[300px]"
          objectFit="cover"
          objectPosition="center"
          placeholder="none"
          alt="hero"
          backgroundColor="transparent"
        />
        <div
          className="absolute inset-0 px-4 py-5"
          style={{
            backgroundImage: `linear-gradient(88deg, rgba(0,0,0,0.55) 34%, rgba(0,0,0,0.22) 56%)`,
          }}
        >
          <h1 className="my-2 text-3xl font-extrabold">작업치료</h1>
          <div className="text-sm leading-6">
            <p>특수작업치료</p>
            <p>일상생활동작치료</p>
            <p>연하치료</p>
            <p>인지치료</p>
          </div>
          <Link to="/rehab/operational">
            <button className="absolute flex items-center justify-start px-5 py-2.5 text-white transition-all bg-white bg-opacity-0 border border-white bottom-4 left-4 right-4 hover:bg-opacity-20">
              더 보기
            </button>
          </Link>
        </div>
      </Section>
      <Footer />
    </Layout>
  );
}
